// src/pages/FriendCollection.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InventoryView } from '../components/InventoryView';
import { useAuth } from '@clerk/clerk-react';
import api from '../lib/api';
import type { ModelVariant } from '../types';

export default function FriendCollection() {
  const { username } = useParams<{ username: string }>();
  const { getToken } = useAuth();
  const [friend, setFriend] = useState<{ id: string; username: string; name: string } | null>(null);
  const [models, setModels] = useState<ModelVariant[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFriendCollection = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const token = await getToken();
        const { data } = await api.get(`/api/users/${username}/collection`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (data.friend) {
          setFriend(data.friend);
        }
        
        setModels(data.models || []);
      } catch (error: any) {
        console.error('Error fetching friend collection:', error);
        setError(error.response?.data?.message || 'Failed to load collection');
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchFriendCollection();
    }
  }, [username, getToken]);

  // These functions are no-ops for read-only view
  const handleToggleOwned = () => {};
  const handleEditNotes = async (id: string, notes: string): Promise<void> => {
    // No-op for read-only view
    return Promise.resolve();
  };

  return (
    <main className="min-h-screen">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : error ? (
        <div className="flex justify-center items-center h-64">
          <div className="text-center p-6 max-w-md bg-white dark:bg-gray-800 rounded-xl shadow-md">
            <p className="text-red-500 dark:text-red-400 mb-4">{error}</p>
            <p className="text-gray-600 dark:text-gray-400">
              This collection may be private or the user doesn't exist.
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              {friend?.name || username}'s Collection
            </h1>
          </div>
          <InventoryView
            models={models}
            onToggleOwned={handleToggleOwned}
            onEditNotes={handleEditNotes}
            readOnly={true}
          />
        </>
      )}
    </main>
  );
}
