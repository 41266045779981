import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, Search, User, BarChart2, Menu, X, Settings, Sun, Moon, Grid, List, AlignLeft, ChevronDown, Users } from 'lucide-react';
import { useUser, useClerk } from '@clerk/clerk-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import type { ViewMode, Friend } from '../types';
import axios from 'axios';

// Create a context for view mode
interface ViewModeContextType {
  viewMode: ViewMode;
  setViewMode: (mode: ViewMode) => void;
}

export const ViewModeContext = React.createContext<ViewModeContextType>({
  viewMode: 'grid',
  setViewMode: () => {},
});

export const useViewMode = () => useContext(ViewModeContext);

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isSignedIn } = useUser();
  const { signOut } = useClerk();
  const { theme, setTheme } = useTheme();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [isPrefetched, setIsPrefetched] = useState<Record<string, boolean>>({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [showFriends, setShowFriends] = useState(false);
  const [friends, setFriends] = useState<Friend[]>([]);
  const [loadingFriends, setLoadingFriends] = useState(false);

  // Update the current path when the location changes
  useEffect(() => {
    setCurrentPath(location.pathname);
    // Close mobile menu when navigating
    setMenuOpen(false);
    // Close settings when navigating
    setSettingsOpen(false);
    // Close friends dropdown when navigating
    setShowFriends(false);
  }, [location.pathname]);

  // Fetch friends
  useEffect(() => {
    // Only fetch friends if we're at the root path (collection view)
    if (currentPath === '/' && isSignedIn && !friends.length) {
      const fetchFriends = async () => {
        try {
          setLoadingFriends(true);
          const { data } = await axios.get('/api/friends');
          
          // Log the response for debugging
          console.log('Friends API response:', data);
          
          // Ensure we handle different response formats
          if (data && data.friends && Array.isArray(data.friends)) {
            setFriends(data.friends);
          } else if (Array.isArray(data)) {
            setFriends(data);
          } else {
            console.log('Friends data not in expected format:', data);
            setFriends([]);
          }
        } catch (error: any) {
          // Only log errors other than 401 (unauthorized) as warnings
          if (error.response?.status === 401) {
            console.log('User not authenticated for friends request');
          } else {
            console.warn('Failed to fetch friends:', error);
          }
          setFriends([]);
        } finally {
          setLoadingFriends(false);
        }
      };
      fetchFriends();
    }
  }, [currentPath, isSignedIn, friends.length]);

  // Handle outside clicks for friends dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (showFriends) {
        const dropdown = document.getElementById('friends-dropdown');
        const button = document.getElementById('friends-button');
        if (dropdown && button && 
            !dropdown.contains(event.target as Node) && 
            !button.contains(event.target as Node)) {
          setShowFriends(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showFriends]);

  // Prefetch data for common routes
  const prefetchRoute = (path: string) => {
    if (isPrefetched[path]) return;
    
    // This is a simple way to prefetch APIs without extra dependencies
    // In a production app, you might want to use a more robust approach or React Query
    const prefetchApis = async () => {
      try {
        console.log(`Prefetching data for ${path}`);
        switch (path) {
          case '/':
            await fetch('/api/collection', { 
              method: 'GET',
              headers: {
                'X-Prefetch': 'true'
              }
            });
            break;
          case '/browse':
            await fetch('/api/models/filters', { 
              method: 'GET',
              headers: {
                'X-Prefetch': 'true'
              }
            });
            await fetch('/api/models?page=1&limit=20', { 
              method: 'GET',
              headers: {
                'X-Prefetch': 'true'
              }
            });
            break;
          case '/stats':
            await fetch('/api/models?count_only=true', { 
              method: 'GET',
              headers: {
                'X-Prefetch': 'true'
              }
            });
            break;
        }
        
        setIsPrefetched(prev => ({
          ...prev,
          [path]: true
        }));
      } catch (error) {
        console.error(`Error prefetching data for ${path}:`, error);
      }
    };
    
    // Only prefetch if the user is signed in to avoid wasting resources
    if (isSignedIn) {
      prefetchApis();
    }
  };

  // Hover handler to trigger prefetching
  const handleMouseEnter = (path: string) => {
    prefetchRoute(path);
  };

  const isActive = (path: string) => {
    if (path === '/' && currentPath === '/') {
      return true;
    }
    return currentPath.startsWith(path) && path !== '/';
  };

  const items = [
    {
      to: '/',
      label: 'Collection',
      icon: <Home className="w-5 h-5" />,
      authenticated: true,
    },
    {
      to: '/browse',
      label: 'Browse',
      icon: <Search className="w-5 h-5" />,
      authenticated: false,
    },
    {
      to: '/stats',
      label: 'Stats',
      icon: <BarChart2 className="w-5 h-5" />,
      authenticated: true,
    },
    {
      to: '/profile',
      label: 'Profile',
      icon: <User className="w-5 h-5" />,
      authenticated: true,
    },
  ];

  // Get visible items based on authentication status
  const visibleItems = items.filter(
    (item) => !item.authenticated || isSignedIn
  );

  // Only show navbar if signed in or if on public routes
  if (!isSignedIn && !['/', '/browse', '/auth', '/model'].some(path => location.pathname.startsWith(path))) {
    return null;
  }

  // Get current page title with special handling for collection page
  const getCurrentPageTitle = () => {
    if (currentPath === '/') {
      return (
        <div className="relative">
          <button
            id="friends-button"
            onClick={() => setShowFriends(!showFriends)}
            className="flex items-center gap-2 text-xl font-bold text-gray-900 dark:text-white hover:text-gray-700 dark:hover:text-gray-200"
          >
            My Collection
            <ChevronDown className={`h-5 w-5 transition-transform duration-200 ${showFriends ? 'rotate-180' : ''}`} />
          </button>
        </div>
      );
    }
    
    const currentItem = visibleItems.find(item => isActive(item.to));
    return <span className="text-xl font-bold text-gray-900 dark:text-white">{currentItem ? currentItem.label : 'Hot Wheels'}</span>;
  };

  // Handle overlay click for settings panel
  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setSettingsOpen(false);
    }
  };

  return (
    <ViewModeContext.Provider value={{ viewMode, setViewMode }}>
      <header className="sticky top-0 z-50 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 px-4">
        <div className="flex items-center justify-between h-12 max-w-7xl mx-auto">
          {/* Mobile: Logo/Title and Hamburger */}
          <div className="flex md:hidden items-center">
            {getCurrentPageTitle()}
          </div>
          
          {/* Desktop: Logo and Navigation */}
          <div className="hidden md:flex items-center space-x-1">
            <h1 className="text-xl font-bold text-gray-900 dark:text-white mr-6">
              Hot Wheels
            </h1>
            
            <nav className="flex items-center space-x-1">
              {visibleItems.map((item) => (
                <Link
                  key={item.to}
                  to={item.to}
                  onMouseEnter={() => handleMouseEnter(item.to)}
                  className={`relative px-3 py-1.5 rounded-md flex items-center ${
                    isActive(item.to)
                      ? 'text-blue-500 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/20'
                      : 'text-gray-700 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                  }`}
                >
                  {item.icon}
                  <span className="ml-2">{item.label}</span>
                </Link>
              ))}
            </nav>
          </div>
          
          {/* Right side buttons */}
          <div className="flex items-center space-x-1">
            <button
              onClick={() => setSettingsOpen(true)}
              className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 rounded-full"
              aria-label="Settings"
            >
              <Settings className="h-5 w-5" />
            </button>
            
            {/* Mobile menu button */}
            <button 
              className="md:hidden p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 rounded-full"
              onClick={() => setMenuOpen(!menuOpen)}
              aria-label={menuOpen ? "Close menu" : "Open menu"}
            >
              {menuOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
            </button>
          </div>
        </div>
        
        {/* Friends dropdown */}
        {showFriends && currentPath === '/' && (
          <div
            id="friends-dropdown"
            className="absolute left-4 md:left-[calc(8rem+5px)] mt-1 w-64 bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 py-2 z-[100]"
          >
            <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center gap-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                <Users className="h-4 w-4" />
                <span>Friends' Collections</span>
              </div>
            </div>
            {loadingFriends ? (
              <div className="px-4 py-3 text-sm text-gray-500 dark:text-gray-400">
                Loading...
              </div>
            ) : friends.length > 0 ? (
              <div className="max-h-64 overflow-y-auto">
                {friends.map(friend => (
                  <button
                    key={friend.id}
                    onClick={() => {
                      navigate(`/friends/${friend.username}/collection`);
                      setShowFriends(false);
                    }}
                    className="w-full px-4 py-2 text-left hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
                  >
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {friend.username}
                    </div>
                    <div className="text-xs text-gray-500 dark:text-gray-400">
                      {friend.collectionCount} models
                    </div>
                  </button>
                ))}
              </div>
            ) : (
              <div className="px-4 py-3 text-sm text-gray-500 dark:text-gray-400">
                No friends added yet
              </div>
            )}
            <div className="px-4 py-2 border-t border-gray-200 dark:border-gray-700">
              <button
                onClick={() => {
                  navigate('/profile', { state: { activeTab: 'friends' } });
                  setShowFriends(false);
                }}
                className="text-sm text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-300"
              >
                Manage Friends
              </button>
            </div>
          </div>
        )}
        
        {/* Mobile menu overlay */}
        <AnimatePresence>
          {menuOpen && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40 md:hidden"
                onClick={() => setMenuOpen(false)}
              />
              <motion.div
                initial={{ x: "-100%" }}
                animate={{ x: 0 }}
                exit={{ x: "-100%" }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                className="fixed top-16 left-0 bottom-0 w-64 bg-white dark:bg-gray-800 z-50 md:hidden shadow-xl border-r border-gray-200 dark:border-gray-700"
              >
                <nav className="flex flex-col p-4 space-y-2">
                  {visibleItems.map((item) => (
                    <Link
                      key={item.to}
                      to={item.to}
                      className={`flex items-center p-3 rounded-lg ${
                        isActive(item.to)
                          ? 'text-blue-500 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/20'
                          : 'text-gray-700 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                      }`}
                    >
                      {item.icon}
                      <span className="ml-3 font-medium">{item.label}</span>
                    </Link>
                  ))}
                  
                  <div className="border-t border-gray-200 dark:border-gray-700 my-2 pt-2">
                    <button
                      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                      className="flex items-center w-full p-3 rounded-lg text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50"
                    >
                      {theme === 'dark' ? 
                        <><Sun className="w-5 h-5" /><span className="ml-3 font-medium">Light Mode</span></> : 
                        <><Moon className="w-5 h-5" /><span className="ml-3 font-medium">Dark Mode</span></>
                      }
                    </button>
                    
                    {isSignedIn && (
                      <button
                        onClick={() => signOut()}
                        className="flex items-center w-full p-3 rounded-lg text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20"
                      >
                        <span className="ml-3 font-medium">Sign Out</span>
                      </button>
                    )}
                  </div>
                </nav>
              </motion.div>
            </>
          )}
        </AnimatePresence>

        {/* Settings Panel */}
        <AnimatePresence>
          {settingsOpen && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50"
                onClick={handleOverlayClick}
              />
              <motion.div
                initial={{ y: "100%" }}
                animate={{ y: 0 }}
                exit={{ y: "100%" }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                className="fixed left-0 right-0 bottom-0 bg-white dark:bg-gray-800 rounded-t-2xl p-6 z-50 max-h-[calc(100vh-5rem)] overflow-y-auto"
              >
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-semibold dark:text-white">Settings</h2>
                  <button
                    onClick={() => setSettingsOpen(false)}
                    className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 rounded-full"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>

                <div className="space-y-6">
                  {/* Theme settings */}
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-3">Theme</h3>
                    <div className="grid grid-cols-2 gap-3">
                      <button
                        onClick={() => {
                          setTheme('light');
                        }}
                        className={`flex items-center justify-center gap-2 p-3 rounded-xl ${
                          theme === 'light'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
                        }`}
                      >
                        <Sun className="h-5 w-5" />
                        <span>Light</span>
                      </button>
                      <button
                        onClick={() => {
                          setTheme('dark');
                        }}
                        className={`flex items-center justify-center gap-2 p-3 rounded-xl ${
                          theme === 'dark'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
                        }`}
                      >
                        <Moon className="h-5 w-5" />
                        <span>Dark</span>
                      </button>
                    </div>
                  </div>

                  {/* View mode settings */}
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-3">View Mode</h3>
                    <div className="grid grid-cols-3 gap-3">
                      <button
                        onClick={() => {
                          setViewMode('grid');
                        }}
                        className={`flex items-center justify-center gap-2 p-3 rounded-xl ${
                          viewMode === 'grid'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
                        }`}
                      >
                        <Grid className="h-5 w-5" />
                        <span>Grid</span>
                      </button>
                      <button
                        onClick={() => {
                          setViewMode('compact');
                        }}
                        className={`flex items-center justify-center gap-2 p-3 rounded-xl ${
                          viewMode === 'compact'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
                        }`}
                      >
                        <AlignLeft className="h-5 w-5" />
                        <span>Compact</span>
                      </button>
                      <button
                        onClick={() => {
                          setViewMode('list');
                        }}
                        className={`flex items-center justify-center gap-2 p-3 rounded-xl ${
                          viewMode === 'list'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
                        }`}
                      >
                        <List className="h-5 w-5" />
                        <span>List</span>
                      </button>
                    </div>
                  </div>

                  {/* Sign out button */}
                  {isSignedIn && (
                    <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
                      <button
                        onClick={() => signOut()}
                        className="w-full flex items-center justify-center p-3 rounded-xl text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20"
                      >
                        <span className="font-medium">Sign Out</span>
                      </button>
                    </div>
                  )}
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </header>
    </ViewModeContext.Provider>
  );
}