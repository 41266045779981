import React, { useState, useEffect } from 'react';
import { 
  Share2, ArrowLeft, ChevronDown, Users 
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import type { ViewMode, Friend } from '../types';
import axios from 'axios';

export interface HeaderProps {
  title: string;
  viewMode?: ViewMode;
  onViewChange?: (mode: ViewMode) => void;
  backButton?: boolean;
  showFriendsDropdown?: boolean;
}

export function Header({ title, viewMode, onViewChange, backButton, showFriendsDropdown = false }: HeaderProps) {
  const [showFriends, setShowFriends] = useState(false);
  const [friends, setFriends] = useState<Friend[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (showFriendsDropdown) {
      const fetchFriends = async () => {
        try {
          setLoading(true);
          const { data } = await axios.get('/api/friends');
          setFriends(data.friends);
        } catch (error) {
          console.error('Failed to fetch friends:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchFriends();
    }
  }, [showFriendsDropdown]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (showFriends) {
        const dropdown = document.getElementById('friends-dropdown');
        const button = document.getElementById('friends-button');
        if (dropdown && button && 
            !dropdown.contains(event.target as Node) && 
            !button.contains(event.target as Node)) {
          setShowFriends(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showFriends]);

  return (
    <header className="bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg border-b border-gray-200 dark:border-gray-800 pt-16">
      <div className="max-w-7xl mx-auto px-4">
        <div className="h-16 flex items-center justify-between">
          <div className="flex items-center gap-3">
            {backButton && (
              <button
                onClick={() => navigate(-1)}
                className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 rounded-full"
              >
                <ArrowLeft className="h-5 w-5" />
              </button>
            )}
            {showFriendsDropdown ? (
              <div className="relative">
                <button
                  id="friends-button"
                  onClick={() => setShowFriends(!showFriends)}
                  className="flex items-center gap-2 text-xl font-bold text-gray-900 dark:text-white hover:text-gray-700 dark:hover:text-gray-200"
                >
                  {title}
                  <ChevronDown className={`h-5 w-5 transition-transform duration-200 ${showFriends ? 'rotate-180' : ''}`} />
                </button>
                
                {showFriends && (
                  <div
                    id="friends-dropdown"
                    className="absolute top-full left-0 mt-1 w-64 bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 py-2 z-[100]"
                  >
                    <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
                      <div className="flex items-center gap-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                        <Users className="h-4 w-4" />
                        <span>Friends' Collections</span>
                      </div>
                    </div>
                    {loading ? (
                      <div className="px-4 py-3 text-sm text-gray-500 dark:text-gray-400">
                        Loading...
                      </div>
                    ) : friends.length > 0 ? (
                      <div className="max-h-64 overflow-y-auto">
                        {friends.map(friend => (
                          <button
                            key={friend.id}
                            onClick={() => {
                              navigate(`/friends/${friend.username}/collection`);
                              setShowFriends(false);
                            }}
                            className="w-full px-4 py-2 text-left hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
                          >
                            <div className="text-sm font-medium text-gray-900 dark:text-white">
                              {friend.username}
                            </div>
                            <div className="text-xs text-gray-500 dark:text-gray-400">
                              {friend.collectionCount} models
                            </div>
                          </button>
                        ))}
                      </div>
                    ) : (
                      <div className="px-4 py-3 text-sm text-gray-500 dark:text-gray-400">
                        No friends added yet
                      </div>
                    )}
                    <div className="px-4 py-2 border-t border-gray-200 dark:border-gray-700">
                      <button
                        onClick={() => {
                          navigate('/profile', { state: { activeTab: 'friends' } });
                          setShowFriends(false);
                        }}
                        className="text-sm text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-300"
                      >
                        Manage Friends
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <h1 className="text-xl font-bold text-gray-900 dark:text-white">
                {title}
              </h1>
            )}
          </div>

          {/* Right side actions could go here if needed */}
        </div>
      </div>
    </header>
  );
}