import { Routes, Route } from 'react-router-dom';
import { SignIn, SignUp, useAuth } from "@clerk/clerk-react";
import { ThemeProvider } from './context/ThemeContext';
import Navbar from './components/Navbar';
import Profile from './pages/Profile';
import Collection from './pages/Collection';
import Stats from './pages/Stats';
import ModelView from './pages/ModelView';
import FriendCollection from './pages/FriendCollection';
import PrivateRoute from './components/PrivateRoute';
import { useEffect } from 'react';
import { setAuthToken } from './lib/api';
import BrowsePage from './pages/Browse';

export default function App() {
  const { getToken } = useAuth();
  
  useEffect(() => {
    const updateToken = async () => {
      try {
        const token = await getToken();
        if (token) {
          console.log("Token received from Clerk successfully");
          setAuthToken(token);
        } else {
          console.warn("No token received from Clerk");
        }
      } catch (error) {
        console.error("Error getting auth token:", error);
      }
    };
    
    updateToken();
    
    // Set up interval to refresh token (optional but recommended)
    const tokenRefreshInterval = setInterval(updateToken, 1000 * 60 * 5); // 5 minutes
    
    return () => clearInterval(tokenRefreshInterval);
  }, [getToken]);

  return (
    <ThemeProvider>
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
        <Navbar />
        <main className="pb-4">
          <Routes>
            <Route 
              path="/sign-in/*" 
              element={
                <div className="min-h-screen flex items-center justify-center p-4">
                  <SignIn 
                    routing="path" 
                    path="/sign-in" 
                    appearance={{
                      elements: {
                        rootBox: "mx-auto w-full max-w-md",
                        card: "bg-white dark:bg-gray-800 shadow-xl rounded-xl p-6",
                        headerTitle: "text-2xl font-bold text-center",
                        headerSubtitle: "text-gray-600 dark:text-gray-400 text-center",
                        socialButtonsBlockButton: "w-full",
                        formButtonPrimary: "bg-blue-500 hover:bg-blue-600 text-white",
                        footerActionLink: "text-blue-500 hover:text-blue-600",
                      }
                    }}
                  />
                </div>
              } 
            />
            <Route 
              path="/sign-up/*" 
              element={
                <div className="min-h-screen flex items-center justify-center p-4">
                  <SignUp 
                    routing="path" 
                    path="/sign-up"
                    appearance={{
                      elements: {
                        rootBox: "mx-auto w-full max-w-md",
                        card: "bg-white dark:bg-gray-800 shadow-xl rounded-xl p-6",
                        headerTitle: "text-2xl font-bold text-center",
                        headerSubtitle: "text-gray-600 dark:text-gray-400 text-center",
                        socialButtonsBlockButton: "w-full",
                        formButtonPrimary: "bg-blue-500 hover:bg-blue-600 text-white",
                        footerActionLink: "text-blue-500 hover:text-blue-600",
                      }
                    }}
                  />
                </div>
              } 
            />
            <Route path="/browse" element={<BrowsePage />} />
            <Route path="/model/:id" element={<ModelView />} />
            <Route 
              path="/" 
              element={
                <PrivateRoute>
                  <Collection />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/stats" 
              element={
                <PrivateRoute>
                  <Stats />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/profile" 
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/friends/:username/collection" 
              element={
                <PrivateRoute>
                  <FriendCollection />
                </PrivateRoute>
              } 
            />
          </Routes>
        </main>
      </div>
    </ThemeProvider>
  );
}