// Add to src/lib/api.ts
import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'https://development.clausen.app',
  withCredentials: true
});

let authToken: string | null = null;

export const setAuthToken = (token: string | null) => {
  authToken = token;
  console.log("Auth token set:", token ? "Token present" : "No token");
};

api.interceptors.request.use(async (config) => {
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
    console.log(`API Request to ${config.url} with auth token`);
  } else {
    console.warn(`API Request to ${config.url} without auth token`);
  }
  return config;
});

// Add response interceptor for debugging
api.interceptors.response.use(
  (response) => {
    console.log(`API Response from ${response.config.url}: Status ${response.status}`);
    return response;
  },
  (error) => {
    console.error(
      `API Error from ${error.config?.url}: Status ${error.response?.status}`,
      error.response?.data || error.message
    );
    return Promise.reject(error);
  }
);

export default api;