// src/components/InventoryView.tsx
import React, { useState } from 'react';
import { Search, Filter, ArrowDown, ArrowUp, X } from 'lucide-react';
import { ModelCard } from './ModelCard';
import { ModelList } from './ModelList';
import { ModelCompact } from './ModelCompact';
import { ModelDetailsModal } from './ModelDetailsModal';
import type { ModelVariant } from '../types';
import { SearchBar } from './SearchBar';
import { SearchFilters } from './SearchFilters';
import { useViewMode } from './Navbar';

interface InventoryViewProps {
  models: ModelVariant[];
  onToggleOwned: (id: string) => void;
  onEditNotes: (id: string, notes: string) => Promise<void>;
  readOnly?: boolean;
}

export function InventoryView({ models, onToggleOwned, onEditNotes, readOnly }: InventoryViewProps) {
  const { viewMode } = useViewMode();
  const [showFilters, setShowFilters] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedModel, setSelectedModel] = useState<ModelVariant | null>(null);
  const [filters, setFilters] = useState({
    year: '',
    series: '',
    color: '',
    sort: 'name-asc'
  });

  // Get unique values for filters
  const uniqueYears = [...new Set(models.map(m => m.year))].sort((a, b) => b - a);
  const uniqueSeries = [...new Set(models.map(m => m.series))].sort();
  const uniqueColors = [...new Set(models.map(m => m.color))].sort();

  // Filter models based on search and filters
  const filteredModels = models.filter(model => {
    const matchesSearch = searchQuery
      ? model.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        model.series.toLowerCase().includes(searchQuery.toLowerCase()) ||
        model.collection_number?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        model.toyNumber?.toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    const matchesYear = !filters.year || model.year.toString() === filters.year;
    const matchesSeries = !filters.series || model.series === filters.series;
    const matchesColor = !filters.color || model.color === filters.color;

    return matchesSearch && matchesYear && matchesSeries && matchesColor;
  });

  // Sort filtered models
  const sortedModels = [...filteredModels].sort((a, b) => {
    switch (filters.sort) {
      case 'newest':
        return b.year - a.year;
      case 'oldest':
        return a.year - b.year;
      case 'name-desc':
        return b.name.localeCompare(a.name);
      case 'series':
        return a.series.localeCompare(b.series) || 
               (a.collection_number || '').localeCompare(b.collection_number || '');
      case 'number':
        const aNum = parseInt((a.collection_number || '').replace(/\D/g, '')) || 0;
        const bNum = parseInt((b.collection_number || '').replace(/\D/g, '')) || 0;
        return aNum - bNum;
      case 'name-asc':
      default:
        return a.name.localeCompare(b.name);
    }
  });

  const getGridColumns = () => {
    switch (viewMode) {
      case 'grid':
        return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6';
      case 'compact':
        return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-2';
      case 'list':
        return 'grid-cols-1 gap-2';
      default:
        return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6';
    }
  };

  const renderModel = (model: ModelVariant) => {
    const props = {
      key: model.id,
      model,
      onToggleOwned,
      onClick: () => setSelectedModel(model),
      onEditNotes: () => onEditNotes(model.id, model.notes || ''),
      readOnly
    };

    switch (viewMode) {
      case 'compact':
        return <ModelCompact {...props} />;
      case 'list':
        return <ModelList {...props} />;
      default:
        return <ModelCard {...props} />;
    }
  };

  const activeFilterCount = 
    (filters.year ? 1 : 0) + 
    (filters.series ? 1 : 0) + 
    (filters.color ? 1 : 0) + 
    (filters.sort !== 'name-asc' ? 1 : 0);

  return (
    <div className="min-h-screen pt-12">
      {/* Search and filter bar - fixed position directly under navbar */}
      <div className="sticky top-0 z-30 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
        <div className="px-4 py-2 flex items-center justify-between gap-2">
          <div className="relative flex-1 max-w-2xl">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <Search className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              className="bg-gray-100 dark:bg-gray-700 border-0 text-gray-900 dark:text-white text-sm rounded-lg block w-full pl-10 p-2.5 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
              placeholder="Search models..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchQuery && (
              <button
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => setSearchQuery('')}
              >
                <X className="h-4 w-4 text-gray-400 hover:text-gray-500" />
              </button>
            )}
          </div>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className={`p-2.5 flex items-center justify-center rounded-lg text-sm font-medium ${
              showFilters || activeFilterCount > 0
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
            }`}
          >
            <Filter className="h-4 w-4" />
            {activeFilterCount > 0 && (
              <span className="ml-1">{activeFilterCount}</span>
            )}
          </button>
        </div>

        {/* Expandable filters */}
        {showFilters && (
          <div className="px-4 py-3 border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800/80">
            <SearchFilters
              filters={filters}
              onFilterChange={setFilters}
              years={uniqueYears}
              series={uniqueSeries}
              colors={uniqueColors}
            />
          </div>
        )}
      </div>

      {/* Results count and sorting display */}
      <div className="px-4 py-3 flex items-center justify-between text-sm text-gray-600 dark:text-gray-400">
        <div>{filteredModels.length} models</div>
        <div className="flex items-center">
          <span className="mr-2">Sorted by:</span>
          <button 
            onClick={() => setShowFilters(true)}
            className="font-medium text-blue-500 dark:text-blue-400 flex items-center"
          >
            {filters.sort === 'name-asc' && 'Name (A-Z)'}
            {filters.sort === 'name-desc' && 'Name (Z-A)'}
            {filters.sort === 'newest' && 'Year (Newest)'}
            {filters.sort === 'oldest' && 'Year (Oldest)'}
            {filters.sort === 'series' && 'Series'}
            {filters.sort === 'number' && 'Collection #'}
            {filters.sort === 'name-asc' ? (
              <ArrowDown className="h-3 w-3 ml-1" />
            ) : filters.sort === 'name-desc' ? (
              <ArrowUp className="h-3 w-3 ml-1" />
            ) : null}
          </button>
        </div>
      </div>

      <div className="px-4 pb-12">
        {sortedModels.length === 0 ? (
          <div className="text-center py-16">
            <p className="text-gray-500 dark:text-gray-400">No models match your filters.</p>
            <p className="text-gray-400 dark:text-gray-500 text-sm mt-2">
              Try adjusting your search terms or filters.
            </p>
            <button
              onClick={() => {
                setSearchQuery('');
                setFilters({
                  year: '',
                  series: '',
                  color: '',
                  sort: 'name-asc'
                });
              }}
              className="mt-4 text-blue-500 dark:text-blue-400 text-sm font-medium"
            >
              Clear all filters
            </button>
          </div>
        ) : (
          <div className={`grid ${getGridColumns()}`}>
            {sortedModels.map(renderModel)}
          </div>
        )}
      </div>

      {selectedModel && (
        <ModelDetailsModal
          model={{ ...selectedModel, owned: true }}
          isOpen={true}
          onClose={() => setSelectedModel(null)}
          onToggleOwned={onToggleOwned}
          onEditNotes={onEditNotes}
        />
      )}
    </div>
  );
}