// src/pages/Search.tsx
import React from 'react';
import { useAuth } from '@clerk/clerk-react';
import { SearchView } from '../components/SearchView';
import { useNavigate } from 'react-router-dom';
import api from '../lib/api';

export default function BrowsePage() {
  const { isSignedIn, getToken } = useAuth();
  const navigate = useNavigate();

  const handleToggleOwned = async (id: string) => {
    if (!isSignedIn) {
      navigate('/auth');
      return;
    }

    try {
      const token = await getToken();
      await api.post(
        '/api/collection/toggle',
        { modelId: id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error('Failed to toggle model ownership:', error);
    }
  };

  const handleEditNotes = async (id: string, notes: string) => {
    if (!isSignedIn) {
      navigate('/auth');
      return;
    }

    try {
      const token = await getToken();
      await api.post(
        '/api/collection/notes',
        { modelId: id, notes },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error('Failed to update notes:', error);
    }
  };

  return (
    <main className="min-h-screen">
      <SearchView 
        onToggleOwned={handleToggleOwned}
        onEditNotes={handleEditNotes}
      />
    </main>
  );
}
