// src/pages/Collection.tsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { InventoryView } from '../components/InventoryView';
import api from '../lib/api';
import type { ModelVariant } from '../types';

export default function Collection() {
  const { getToken } = useAuth();
  const [models, setModels] = useState<ModelVariant[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        setLoading(true);
        const token = await getToken();
        const { data } = await api.get('/api/collection', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        // Log the response structure to diagnose the issue
        console.log('Collection API response:', data);
        
        // Ensure models is an array
        if (Array.isArray(data)) {
          setModels(data);
        } else if (data && typeof data === 'object') {
          // Handle the case where the API returns an object with a models property
          if (Array.isArray(data.models)) {
            setModels(data.models);
          } else if (Array.isArray(data.collection)) {
            setModels(data.collection);
          } else {
            // No recognizable array found, set to empty array to prevent errors
            console.error('API response does not contain a models array:', data);
            setModels([]);
          }
        } else {
          // Set an empty array if data is not in expected format
          console.error('Unexpected API response format:', data);
          setModels([]);
        }
      } catch (error) {
        console.error('Error fetching collection:', error);
        setModels([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCollection();
  }, [getToken]);

  const handleToggleOwned = async (id: string) => {
    try {
      const token = await getToken();
      await api.post(
        '/api/collection/toggle',
        { modelId: id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update the models state
      setModels((prevModels) =>
        prevModels.filter((model) => model.id !== id)
      );
    } catch (error) {
      console.error('Failed to toggle model:', error);
    }
  };

  const handleEditNotes = async (id: string, notes: string) => {
    try {
      const token = await getToken();
      await api.post(
        '/api/collection/notes',
        { modelId: id, notes },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update the notes in our local state
      setModels((prevModels) =>
        prevModels.map((model) =>
          model.id === id ? { ...model, notes } : model
        )
      );
    } catch (error) {
      console.error('Failed to update notes:', error);
    }
  };

  return (
    <main className="min-h-screen">
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <InventoryView
          models={models}
          onToggleOwned={handleToggleOwned}
          onEditNotes={handleEditNotes}
        />
      )}
    </main>
  );
}
